<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-row justify="start" align="center">
            <v-col cols="12">
                <v-subheader class="py-1 px-0">Варианты, недоступные для активации скретч-картами</v-subheader>
            </v-col>
        </v-row>

        <div>
            <v-card
                v-for="(subject, i) in form.exceptedOptions"
                :key="i"
                elevation="4"
                class="mb-4"
            >
                <v-card-title>
                    <div>{{ subject.subject }}</div>
                </v-card-title>

                <v-card-text>
                    <div>
                        <div v-for="grade in subject.grades" :key="grade.grade" class="my-6 d-flex flex-row justify-start align-start">
                            <div class="mr-4">
                                <v-subheader class="py-1 px-0 text-no-wrap">{{ grade.grade }} кл.</v-subheader>
                            </div>
                            <div class="flex-grow-1">
                                <div>
                                    <v-chip
                                        v-for="option in getMaxOption()"
                                        :key="option"
                                        class="ma-2"
                                        :color="grade.options.includes(option) ? 'error' : 'success'"
                                        text-color="white"
                                        @click.prevent.stop="toggleOption(grade, option)"
                                    >
                                        <v-avatar v-if="grade.options.includes(option)" left>
                                            <v-icon>mdi-close</v-icon>
                                        </v-avatar>
                                        {{option}}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <v-row justify="start" align="center">
            <v-col class="col-auto">
                <v-btn 
                    v-if="!loading"
                    dark 
                    outlined 
                    :color="$const.color.primary" 
                    small
                    @click.prevent.stop="back"
                >
                    <v-icon left dark>
                        mdi-arrow-left
                    </v-icon>
                    Назад к списку
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="col-auto">
                <v-btn 
                    dark 
                    :color="$const.color.primary"
                    small
                    :loading="loading" 
                    @click.prevent.stop="save"
                >
                    Сохранить
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'

const MAX_OPTION = 50;

export default {
    name: 'ShortCodeSettingsForm',
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            loading: false,
            form: {
                exceptedOptions: []
            }
        };
    },
    computed: {
        ...mapState('license_type', ['subjects']),
        ...mapGetters('license_type', ['grades'])
    },
    methods: {
        async fetch() {
            const { data } = await this.$store.dispatch('short_code/fetchSettings', {
                param: 'exceptedOptions'
            });
            return data ? JSON.parse(data) : {};
        },
        prepareForm(form) {
            let nForm = _.cloneDeep(form);
            let subjects = {};
            _.map(nForm.exceptedOptions, s => {
                _.map(s.grades, g => {
                    _.setWith(subjects, `${s.subject}.${g.grade}`, g.options, Object);
                });
            });
            nForm.exceptedOptions = subjects;
            
            return {
                settings: [{
                    param: 'exceptedOptions',
                    value: JSON.stringify(nForm.exceptedOptions)
                }]
            }
        },
        initForm(subjects = {}) {
            return _.map(_.filter(this.subjects, s => s.value !== '*'), o => {
                return {
                    subject: o.value,
                    grades: _.map(o.grades, grade => {
                        return {
                            grade: grade,
                            options: _.get(subjects, `${o.value}.${grade}`, [])
                        }
                    })
                }
            })
        },
        getMaxOption() {
            return MAX_OPTION;
        },
        toggleOption(grade, option) {
            if(grade.options.includes(option))
                this.$set(grade, 'options', _.filter(grade.options, v => v !== option));
            else
                grade.options.push(option);
        },
        async save() {
            this.loading = true;
            this.serverErrors = null;

            const { success, error, data } = await this.$store.dispatch(`short_code/saveSettings`, {
                ...this.prepareForm(this.form)
            });

            if(success)
            {
                this.$emit('success', data);
                this.back();
                return ;
            }
            else
                this.serverErrors = error;

            this.loading = false;
            return;
        },
    },  
    async created() {
        const data = await this.fetch();
        this.$set(this.form, 'exceptedOptions', this.initForm(data));
    }
}
</script>
<style lang="scss">
    .license-type-form{
        .inactive{
            opacity: .35;
            pointer-events: none!important;
        }
        .v-input--checkbox.v-input--selection-controls.dense{
            margin: 0;
            padding: 0;
        }
        .v-subheader{
            height: auto;
        }
    }
</style>