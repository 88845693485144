<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Настройки"
        create
        url-back="/short_code"
    >
        <short-code-settings-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import ShortCodeSettingsForm from '@/components/forms/ShortCodeSettingsForm.vue'
export default {
    name: 'ShortCodeSettings',
    components: { FormView, ShortCodeSettingsForm }
}
</script>